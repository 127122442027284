.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


* {
  text-align: center;
}

.logo {
  margin: auto
}

.jumbotron {
  margin: 100px auto;
  max-width: 50%;
  text-align: center;
}

.card {
  width: 18rem;
  margin: 100px auto;
}

.fullheightfixed {
  overflow: auto;
  max-height: calc(75vh);
}

.color1 {
  background-color: #27A2BB !important;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}

.color1A {
  background-color: #27A2BB !important;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: small;
}

.color2 {
  background-color: #478ba2 !important;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400;
}

.txt1 {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400;
  font-size: smaller;
}

.txt2 {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400;
  font-size: small;
}

.bg1 {
  background-color: #FAB10C;
}

.brd1 {
  border-color: #27A2BB !important;
}